a.tabItem {
  padding: 0px;
  letter-spacing: var(--g1-typography-body2-letter-spacing);
  border-bottom: 2px solid transparent; // this offsets the bottom border when it's active or hovered
  color: var(--g1-color-galaxy-black-100);
  line-height: 78px;

  &:hover,
  &.active {
    color: var(--g1-color-galaxy-black-100);
    border-bottom-color: var(--g1-color-galaxy-black-100);
  }
}
