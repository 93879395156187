@use 'client/src/styles/colors.module.scss';

.red {
  color: colors.$red;
}
.green {
  color: colors.$green;
}

.errorHighlight {
  background-color: colors.$dimRedHighlight;
}
.warningHighlight {
  background-color: colors.$dimYellowHighlight;
}
