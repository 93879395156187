// DO NOT ADD MORE GLOBAL CSS
// INSTEAD USE CSS MODULES

@import 'antd/dist/antd.css';
// Undoes certain unwanted global styles from AntD (GTO-6742). Keep import right after antd.css.
@import 'styles/antd-revert-global-styles.css';
@import 'semantic-ui-css/semantic.min.css';
@import '@g1/components/style.css';

@import 'react-dates/lib/css/_datepicker.css';
@import 'react-datetime/css/react-datetime.css';
@import 'react-phone-number-input/style.css';
@import 'react-toastify/dist/ReactToastify.css';

@import 'components/AdminPanel/AdminPanel.scss';
@import 'components/Counterparty/CounterpartySearch.scss';
@import 'components/Draggable/Draggable.scss';
@import 'components/Galaxy/GalaxyGrid.scss';
@import 'components/GridTable/GridTable.scss';
@import 'components/LiveRiskV2/LiveRisk.scss';
@import 'components/ReorganizableGrid/ReorganizableGrid.scss';
@import 'components/Settlements/CreateSettlementModal.scss';
@import 'components/Settlements/RequestSettlementModal.scss';
@import 'components/Settlements/settlements.scss';
@import 'components/Settlements/styles.scss';
@import 'components/SwitchButton/SwitchButton.scss';
@import 'components/TradeBlotter/Daily.scss';
@import 'components/Transfers/TransferBlotter.scss';
@import 'components/VirtualizedSelect/virtualized-select.scss';

@import 'Styles.scss';
