@use 'client/src/styles/colors.module.scss';

:global(.ui.small.image).logo {
  width: 120px;
  margin-left: 30px;
}

.mainNavMenu {
  position: relative;
  width: 100%;
  height: auto;

  :global {
    .ui.menu {
      &.fixed {
        height: 44px;
        width: 100%;
        display: grid;
        grid-template-columns: 230px auto 340px;
        background-color: colors.$background-primary;
        border-bottom: 2px solid colors.$sidebarBorder;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &:global(.inverted) {
        .item {
          color: colors.$darkTextColor;
          justify-content: center;
          padding: 4px;

          &:hover {
            color: colors.$textColor;
          }

          .image {
            margin-right: 5px;
          }

          span {
            font-size: 14px;
          }

          &::before {
            display: none;
          }
        }
      }

      :local(.centerItemsContainer) {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: baseline;
        :global .item {
          height: 100%;
          padding: 4px 1rem;
        }
      }
    }

    .left.menu .item {
      display: block;
      margin-right: 50px;
      padding-left: 20px;

      :local(.galaxyLogo) {
        width: calc(100% - 34px) !important;
      }
    }
  }

  .userProfile {
    position: relative;

    div {
      position: absolute;
      top: 7px;
      left: 3.5px;
      font-size: 14px;
    }

    img {
      z-index: -1;
    }
  }

  .rightMenuContainer {
    display: block;
    position: relative;

    :global .right.menu {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;

      :global .item {
        color: colors.$textColor;
        font-size: 16px;
        margin: 0 4px;
      }
    }
  }
}
