:has(> .root) {
  grid-row: 1 / -1;
}

.root {
  height: 100%;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, min-content) minmax(0, 1fr);
}

.root > nav {
  display: flex;
  height: 80px;
  padding: 0px 40px;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid var(--g1-color-galaxy-black-600, #323232);
  background: var(--g1-color-galaxy-black-800, #000);
}

.container {
  background: url('/assets/login-error-background.png');
  background-size: cover;

  display: flex;
  padding-bottom: var(--g1-spacing-s);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--g1-spacing-xl);
  flex-shrink: 0;
}

.card {
  display: flex;
  width: 760px;
  padding: var(--g1-spacing-xxl);
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-s);
  border-radius: var(--g1-spacing-xs);
  border: 1px solid var(--g1-color-galaxy-black-600, #323232);
  background: var(--g1-color-galaxy-black-800, #000);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-l);
  align-self: stretch;
}

.contentText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-xs);
  align-self: stretch;
}

.actions {
  display: flex;
  padding-top: var(--g1-spacing-m);
  align-items: flex-start;
  gap: var(--g1-spacing-m);
  align-self: stretch;
  justify-content: center;
}

.actions a {
  color: var(--g1-color-galaxy-black-100, #fff);
}

.title {
  color: var(--g1-color-galaxy-black-100, #fff);
  text-align: center;
  align-self: stretch;
  text-transform: uppercase;
}

.message {
  color: var(--g1-color-galaxy-black-400, #9fa1a3);
  text-align: center;
  align-self: stretch;
}
