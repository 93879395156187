.footer {
  position: absolute;
  top: 100vh;
  left: 0;
  right: 0;

  background-color: var(--g1-color-galaxy-black-800);

  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--g1-color-galaxy-black-600);
  padding: var(--g1-spacing-m) 40px;

  .footerContainer {
    display: flex;
    flex-direction: column;
    gap: var(--g1-spacing-m);
  }

  .footerLinks {
    display: flex;
    gap: var(--g1-spacing-m);

    a,
    a:active,
    a:hover,
    a:visited {
      color: var(--g1-color-galaxy-black-100);
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .soc2Container {
    display: flex;
    gap: var(--g1-spacing-s);

    .soc2Image {
      width: 64px;
      height: 64px;
    }

    .soc2Text {
      max-width: 350px;
      color: var(--g1-color-galaxy-black-400);
    }
  }
}
