.logo {
  gap: 16px;
}

.scrolling {
  overflow-y: auto;
}

.navbar {
  :global(.ui.inverted.menu) {
    &.navMenu {
      flex-wrap: wrap;

      background-image: url('/assets/navmenu.png?fl=progressive');
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0;
    }
  }
}
