//Text colors
$darkTextColor: #707070;
$textColor: #ffffff;
$grey: #595b5e;
$grey-light: #b3b5be;
$indicator: rgb(204, 204, 204);
$green: #49aa19;
$green-01: #00a477;
$red: #d63038;
$white: #ffffff;
$black: #000000;

//Galaxy gradient colors
$startGradient: #1f4caa;
$endGradient: #6a07c2;
$scaleGradientStart: $green-01;
$scaleGradientMiddle: #d9d46b;
$scaleGradientEnd: #f26579;

//Background colors
$background-primary: #0f1217;
$background-secondary: #1b1d23;
$tertiaryBackground: #24262c;
$scrollbarBackground: #0a0e12;
$primaryBlue: #214aaa;
$bidBackground: rgba(85, 150, 50, 0.2);
$askBackground: rgba(179, 61, 62, 0.2);
$modalBackground: #1a1d22;
$trading-view-background: #131722;
$order-entry-background: #282e2f;

$locationBar: #25272c;
$toggleBorder: #35373d;
$sidebarBorder: #262a2d;
$trading-view-border: #313540;

$redButton: #662c30;
$darkGreen: #38592a;
$darkGrey01: #313540;
$darkGrey02: #1d222e;
$lightGrey01: #b3b5bd;
$lightGrey02: #8f9096;
$lightGrey03: #50535c;
$white: #fff;
$red02: #a83d51;
$green02: #1e7159;
$orderBookAskBackgroundHover: rgba(240, 100, 120, 0.3);
$orderBookBidBackgroundHover: rgba(0, 164, 119, 0.3);
$orderBookAskBackground: rgba(240, 100, 120, 0.05);
$orderBookBidBackground: rgba(0, 164, 119, 0.05);

$toastError: #cb2029;
$toastWarning: #ffdb00;
$toastSuccess: #49aa19;

$brightGreen: #2fba30;
$blue: #499ae5;

$deleteButton: #db2828;

$dimRedHighlight: $redButton;
$dimYellowHighlight: $scaleGradientMiddle;

:export {
  darkTextColor: $darkTextColor;
  textColor: $textColor;
  gotcLabel: $grey;
  indicator: $indicator;
  greenHighlight: $green;
  sell: $red;

  //Galaxy gradient colors
  startGradient: $startGradient;
  endGradient: $endGradient;
  scaleGradientStart: $scaleGradientStart;
  scaleGradientMiddle: $scaleGradientMiddle;
  scaleGradientEnd: $scaleGradientEnd;

  //Background colors
  tertiaryBackground: $tertiaryBackground;
  scrollbarBackground: $scrollbarBackground;
  primaryBlue: $primaryBlue;
  bidBackground: $bidBackground;
  askBackground: $askBackground;
  modalBackground: $modalBackground;

  locationBar: $locationBar;
  toggleBorder: $toggleBorder;
  sidebarBorder: $sidebarBorder;

  redButton: $redButton;
  greenButton: $darkGreen;

  toastError: $toastError;
  toastWarning: $toastWarning;
  toastSuccess: $toastSuccess;

  deleteButton: $deleteButton;

  brightGreen: $brightGreen;
}
