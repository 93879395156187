.SystemErrorPage {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, min-content) minmax(0, 1fr);
}

.MainNavigation {
  display: flex;
  height: 80px;
  padding: 0px 40px;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid var(--Galaxy-Black-600, #323232);
  background: var(--Galaxy-Black-800, #000);
}

.MainContainer {
  background: url('/assets/login-error-background.png');
  background-size: cover;

  display: flex;
  padding-bottom: var(--g1-spacing-s);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--g1-spacing-xl);
  flex-shrink: 0;
}

.ModalBox {
  display: flex;
  width: 760px;
  padding: var(--g1-spacing-xxl);
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-s);
  border-radius: var(--g1-spacing-xs);
  border: 1px solid var(--Galaxy-Black-600, #323232);
  background: var(--Galaxy-Black-800, #000);
}

.ModalBoxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-l);
  align-self: stretch;
}

.ModalBoxContentText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-xs);
  align-self: stretch;
}

.ModalBoxButtonGroup {
  display: flex;
  padding-top: var(--g1-spacing-m);
  align-items: flex-start;
  gap: var(--g1-spacing-m);
  align-self: stretch;
  justify-content: center;
}
.ModalBoxButtonGroup a {
  color: var(--Galaxy-Black-100, #fff);
}

.Title {
  color: var(--Galaxy-Black-100, #fff);
  text-align: center;
  align-self: stretch;
  text-transform: uppercase;
}

.Message {
  color: var(--Galaxy-Black-400, #9fa1a3);
  text-align: center;
  align-self: stretch;
}

.SignOutButton :global(.MuiTypography-buttonText) {
  padding-right: var(--g1-spacing-m);
}