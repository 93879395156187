h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: revert;
  margin-bottom: revert;
  color: revert;
  font-weight: revert;
}

html,
body {
  width: revert;
  height: revert;
}
html {
  font-family: revert;
  line-height: revert;
  -webkit-text-size-adjust: revert;
  -ms-text-size-adjust: revert;
  -ms-overflow-style: revert;
  -webkit-tap-highlight-color: revert;
}
body {
  margin: revert;
  color: revert;
  font-size: revert;
  font-family: revert;
  font-variant: revert;
  line-height: revert;
  background-color: revert;
  font-feature-settings: revert;
}

input,
button,
select,
optgroup,
textarea {
  margin: revert;
  color: revert;
  font-size: revert;
  font-family: revert;
  line-height: revert;
}
button,
input {
  overflow: revert;
}
