@use 'client/src/styles/typography.module.scss';

$report_page_margin: 150px;
$app_container_top_margin: 115px;

$banner_height: 35px;
$double_banner_height: $banner_height + $banner_height;

/**
 * ----------------------------------------
 * [start] app layout
 * ----------------------------------------
 */
#root {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  overflow-x: auto;
}

/**
 * ----------------------------------------
 * [end] app layout
 * ----------------------------------------
 */

.ag-cell {
  contain: strict;
}

ul {
  margin: 0;
  padding: 0;
}

.header-border {
  height: 20px;
  margin-top: 10px;
  border-top: 2px solid #499ae5;
  width: 100%;
}

.hidden {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}
.ui.orange > .hidden.content {
  display: block;
}
.content-wrapper {
  height: 100%;
  padding-bottom: 20px;
  position: relative;
}

.daily-blotter-wrapper,
.historical-tb-root > .col-content {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
}

.page-container {
  height: 100%;
}

.galaxy-container {
  height: 100%;
}

.custom-col-3 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.loan-status {
    display: grid;
    grid-template-rows: auto;
  }
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 1050px) {
  .custom-col-3 {
    padding-left: $report_page_margin;
    padding-right: $report_page_margin;
  }
}

.galaxy-header {
  padding-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

.col-content {
  min-width: 790px;
  position: relative;
}

.daily-cno {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 99;
}

.ag-floating-bottom-viewport,
.ag-pinned-right-floating-bottom {
  background: #dcddde;
  color: #003870;
  font-weight: 500;
}

.relative {
  position: relative;
}

/*! Galaxy Header Styles */
// .galaxy-header styles
.subheader {
  box-sizing: content-box;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-family: typography.$body-font;
  font-size: 1.15rem; // 1.14285714rem;
  font-style: italic;
  line-height: 1.2em;
}

// LED Status lights
%status-base {
  content: '';
  position: absolute;
  top: 3px;
  width: calc(100% - 24px);
  left: 12px;
  height: 3px;
  background: #d3d3d3;
}

.status::before {
  @extend %status-base;
}

.status-green::before {
  @extend %status-base;
  background-color: #21ba45;
}

.status-yellow::before {
  @extend %status-base;
  background-color: #ffb812;
}

.status-red {
  @extend %status-base;
  background: rgba(255, 0, 0, 0.1);
  border-top: 3px solid red !important;
  box-shadow: 0 0 5px 0px #000000b8;
  height: inherit;
  margin-left: 12px;
  width: calc(100% - 12px) !important;

  span.ag-header-cell-text {
    line-height: 21px;
  }
}

.status-red::before {
  @extend %status-base;
  // background-color: #f00;
  background-color: transparent;
}
// END LED Status lights

.flex {
  display: flex;
}

.error-text {
  margin: 0.25em 0 0 0;
  padding: 0;
  color: red;
  font-style: bold;
  font-size: 1.2rem;
}

.ui.pagination.menu {
  margin: 20px 20px 0 0;
}

.ui.grid > .row {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.ag-pinned-left-header,
.ag-pinned-right-header {
  .status-red {
    height: calc(
      100% - 3px
    ); // Subtract 3 pixels that were added by status-red class using position absolute / top: 3px
  }
}

.ag-header-cell-text {
  width: 100%;
  text-align: center;
}

.ag-cell-label-container {
  .ag-header-icon.ag-header-cell-menu-button {
    float: right;
  }
}

.filter-trade-blotter-dropdown {
  display: inline-block;
}

.transfers-root,
.live-risk-root,
.historical-tb-root,
.daily-trade-blotter {
  .ui.selection.dropdown {
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 8px;
    position: relative;
    &,
    i.icon {
      padding: 4px;
      padding-left: 8px;
      height: inherit;
      min-height: auto;
      &:before {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
}

.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #97cdff;
  border-radius: 2px;
  overflow: hidden;
  .indeterminate {
    background-color: #499ae5;
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      animation-delay: 1.15s;
    }
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.galaxy-new-container {
  box-sizing: border-box;
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: 100%;
}

.ui.dropdown .visible.menu {
  z-index: 99;
}

.ui.right.labeled.input {
  .ui.input input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ui.label {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.new-transfer-modal-unallocated-transfers,
.new-transfer-modal-unsettled-trades {
  min-height: 100px !important;
  max-height: 500px !important;
  .highlighted {
    &,
    .grid-table-row,
    .grid-table-cell {
      background-color: rgba(255, 255, 0, 0.5);
    }
  }
  .grid-table-root {
    height: 250px;
  }
}

.new-transfer-modal-unallocated-transfers .ui.input > input,
.new-transfer-modal-unsettled-trades .ui.input > input {
  box-sizing: border-box;
  background-color: rgb(255, 255, 236);
  border: 2px solid white;
  border-style: inset;
  width: 100%;
  border-radius: 0;
}

.thin-input {
  span,
  .ui,
  i,
  .icon {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 24px;
    height: 24px;
    vertical-align: baseline;
  }
  span,
  i,
  .icon {
    line-height: 24px;
  }
  input {
    height: 25px;
    line-height: 24px;
    vertical-align: baseline;
  }
  i,
  input,
  .ui.label,
  .ui.labeled.input > .label:not(.corner) {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .selection.dropdown,
  .ui.selection.dropdown .menu > .item {
    font-size: 14px;
    line-height: 14px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    user-select: none;
    min-height: 0;
  }
  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    padding: 2px;
  }

  .ui.input {
    max-width: 100%;
  }
}

svg {
  contain: strict;
}

.ui.menu .ui.dropdown {
  &,
  & .menu {
    & > .active.item {
      color: orange !important;
      text-shadow: 1px 1px 1px #333;
    }
  }
}

.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
.nocontent {
  font-family: typography.$body-font;
  font-weight: 400;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.ui.menu .dropdown.item .menu {
  z-index: 9999;
}

@media only screen and (min-width: 768px) {
  .create-settlement-dialog {
    width: 88% !important;
  }
}
@media only screen and (min-width: 992px) {
  .create-settlement-dialog {
    width: 1020px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .create-settlement-dialog {
    width: 1080px !important;
    margin: 0;
  }
}
@media only screen and (min-width: 1920px) {
  .create-settlement-dialog {
    width: 1200px !important;
    margin: 0;
  }
}

.counterparty-search-container {
  float: right;
}
.entities-list > div {
  max-height: 700px;
  min-width: 200px;
}

ul ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.disabled {
  opacity: 0.6 !important;
}
.disabled > label,
.disabled .disabled {
  opacity: 1 !important;
}
.left.ui {
  .search,
  .selection,
  .dropdown,
  .input input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.modal .actions {
  min-height: 68px;
}

.galaxy-header-flex {
  h1 {
    margin-right: 36px;
    margin-bottom: 0;
  }
  .subheader {
    display: block;
    line-height: 36px;
    display: inline;
    margin-top: 5px;
    margin-bottom: -5px;
  }
}

.blotter-base-controls,
.filter-controls .filter-controls {
  margin-bottom: 10px;
  &,
  & > * {
    height: 24px;
    vertical-align: baseline;
  }
  > * {
    border-right: 1px solid #bdc3c7;
    padding-right: 18px;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
      border-right: 0;
    }
  }
}

.thin-input {
  .ui.toggle.checkbox {
    label {
      &:before,
      &:after {
        top: 4px;
        height: 18px;
      }
      &:before {
        width: 35px;
      }
    }
  }
  .ui.toggle.checkbox input:checked ~ label:after {
    left: 20px;
  }
  .ui.toggle.checkbox label {
    padding-left: 50px;
  }
}

.cell-align-left {
  text-align: left;
  padding-left: 2px;
}
.cell-align-right {
  text-align: right;
  padding-right: 2px;
}

.gotc-monitor-saving-max-qty .ui.input {
  background-color: #fff;
  background: repeating-linear-gradient(
    135deg,
    rgba(77, 244, 83, 0.5) 0 5px,
    transparent 5px 10px
  );
  background-size: 28px 100%;
  animation: gotc-monitor-animate-save 5s linear infinite;
}

@keyframes gotc-monitor-animate-save {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

html {
  display: grid;
}

// Button Reset
button:not(:global(.MuiButtonBase-root)) {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: typography.$body-font;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition:
    background 250ms ease-in-out,
    transform 150ms ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background: #0053ba;
  }

  &:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
  }

  &:active {
    transform: scale(0.99);
  }
}
