.environmentNotice {
  background-color: var(--bg-color);
  color: var(--text-color);
  text-shadow: var(--text-shadow, none);
  padding-block: var(--vertical-spacing);

  &:where(.internal, .get) {
    --orange: #ffa500;
    --blue: #0328a2;
    --vertical-spacing: var(--g1-spacing-xs);

    font-size: 2rem;
    font-weight: bold;
  }

  &.internal {
    --bg-color: var(--orange);
    --text-color: var(--blue);
    --text-shadow: 0 2px 1px #ffe0a7;
  }

  &.get {
    --bg-color: var(--blue);
    --text-color: var(--orange);
    --text-shadow: 0 2px 1px #000;
  }

  &.g1 {
    --vertical-spacing: var(--g1-spacing-s);
    --bg-color: var(--g1-color-galaxy-black-800);
    --text-color: var(--g1-color-earthly-green-200);

    border-bottom: 1px solid currentColor;

    font-family: var(--g1-font-family-text);
    font-weight: var(--g1-typography-body1-font-weight);
    font-size: var(--g1-typography-body1-font-size);
  }

  .message {
    width: fit-content;
    margin-inline: auto;
  }
}
