.LegalTermsContainer {
  background-image: url('/assets/galaxy-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.Modal {
  &:global(.g1-modal) {
    :global(.MuiPaper-root.MuiDialog-paper), :global(.MuiScopedCssBaseline-root) {
      color: var(--g1-color-galaxy-black-800, #000);
      background-color: var(--g1-color-galaxy-black-100, #fff);
    }

    :global(.MuiButton-root.MuiButton-outlined) {
      border-color: var(--g1-color-galaxy-black-800, #000);
      color: var(--g1-color-galaxy-black-800, #000);
      background-color: var(--g1-color-galaxy-black-100, #fff);
    }
    
    :global(.MuiButton-root.MuiButton-outlined):hover {
      background-color: rgba(0 0 0 / 0.04);
    }

    :global(.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary) {
      border-color: var(--g1-color-galaxy-black-100, #fff);
      color: var(--g1-color-galaxy-black-100, #fff);
      background-color: var(--g1-color-galaxy-black-800, #000);
    }

    :global(.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary):hover {
      background-color: var(--g1-color-galaxy-black-500, #6D6E71); 
    }

    :global(.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled) {
      color: var(--g1-color-galaxy-black-400, #9FA1A3);
      background-color: var(--g1-color-galaxy-black-300, #DCDCDC);
    }

    :global(.MuiDivider-root) {
      border-color: var(--g1-color-galaxy-black-300, #DCDCDC);
    }
  }
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--g1-spacing-s);
  text-align: center;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  gap: var(--g1-spacing-m);
}

.Content {
  display: flex;
  flex-direction: column;
  gap: var(--g1-spacing-m);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ScrollableContent {
  display: flex;
  height: 340px;
  padding: var(--g1-spacing-xxs);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: auto;

  border-radius: var(--g1-spacing-xxs);
  border: 1px solid var(--g1-color-galaxy-black-800, #000);
  background: var(--g1-color-galaxy-black-100, #fff);
}

.Greyed {
  color: var(--g1-color-galaxy-black-500, #6d6e71);
}

.SupportingText {
  @extend .Greyed;
  text-align: center;
}

.Buttons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--g1-spacing-s);
  width: 100%;
}

.ReadMarker {
  height: 1px;
}

.LoadingIndicator {
  align-self: center;
}

.LegalTermsText {
  ul {
    list-style-position: inside;
    list-style-type: '-';

    li {
      padding-inline: var(--g1-spacing-s);

      p {
        padding-inline: var(--g1-spacing-s);
        display: inline;
      }
    }
  }
}

.Header1, .Header2, .Header3, .Header4, .Paragraph {
  color: var(--g1-color-galaxy-black-800);
  background-color: var(--g1-color-galaxy-black-100);
}

.Header1 {
  &:global(.MuiTypography-root) {
    margin-block-start: var(--g1-spacing-xxl);
    margin-block-end: var(--g1-spacing-l);
  }
}

.Header2 {
  &:global(.MuiTypography-root) {
    margin-block-start: var(--g1-spacing-xl);
    margin-block-end: var(--g1-spacing-m);
  }
}

.Header3 {
  &:global(.MuiTypography-root) {
    margin-block-start: var(--g1-spacing-l);
    margin-block-end: var(--g1-spacing-s);
  }
}

.Header4 {
  &:global(.MuiTypography-root) {
    margin-block-start: var(--g1-spacing-m);
    margin-block-end: var(--g1-spacing-xs);
  }
}
