@use 'client/src/styles/colors.module.scss';

.creditBanner {
  width: 100%;
  background-color: colors.$toastError;
  text-align: center;
  z-index: 101;
  color: colors.$textColor;
  font-weight: 700;
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.maintenanceBanner {
  width: 100%;
  background: colors.$darkGrey02;
  border: 1px solid colors.$darkGrey01;
  color: colors.$white;
  box-sizing: border-box;
  text-align: center;
  z-index: 101;
  font-weight: 700;
  padding-top: 5px;
  top: 0px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  & > span {
    padding-left: 4px;
  }
}

.closeButton {
  padding-left: 10px;
  margin-top: -7px;
  cursor: pointer;
}

.readOnlyBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--g1-color-galaxy-black-400);
  height: 56px;

  .boldContent {
    padding-inline: var(--g1-spacing-xs);
  }
}