@use 'client/src/styles/colors.module.scss';

@mixin galaxy-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    background-color: colors.$scrollbarBackground;
    border: 1px solid transparent;
    border-radius: 5px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid transparent;
    background-color: colors.$locationBar;
    background-clip: padding-box;
  }
}
