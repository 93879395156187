.switch-button-root {
  display: inline-block;
}
.not-switch-button-root,
.switch-buttin-wrapper {
  border-radius: 4px;
  border: 1px solid rgb(178, 178, 178);
  cursor: pointer;
  box-shadow: inset 0px 6px 6px 0px #00000022;
  background-color: rgb(244, 244, 244);
  background: linear-gradient(
    189deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 100%
  );
  overflow: hidden;
  user-select: none;
  .label {
    transform: translateY(-1px);
    margin-bottom: -2px;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font: 14px/100% Arial, Helvetica, sans-serif;
    display: inline-block;
    padding: 8px 16px;
    border-right: 1px solid rgb(178, 178, 178);
    border-radius: 0px;
    text-shadow: 1px 1px 1px rgb(255, 255, 255);

    color: rgb(164, 164, 164);
    background: rgb(244, 244, 244);

    &:hover {
      color: rgb(110, 110, 110);
      font-weight: bold;
    }
    &.active {
      background: transparent;
      color: rgb(110, 110, 110);
      font-weight: bold;
    }
  }
  .label {
    box-shadow: inset 0px -10px 10px 1px #00000011, 0px 0px 4px 1px #00000022;
  }
  .prev-label {
    box-shadow: inset 0px -10px 10px 1px #00000011, 4px 0px 4px 1px #00000016;
  }
  .next-label {
    box-shadow: inset 0px -10px 10px 1px #00000011, -4px 0px 4px 1px #00000022;
  }
  .last-label {
    border-right: none;
  }
}

.flat.not-switch-button-root,
.flat.switch-button-root {
  .label,
  .switch-buttin-wrapper {
    box-shadow: none;
  }
  .switch-button-wrapper {
    background-color: #e8e8e8;
  }
  .label {
    padding: 4px 8px;
    &.active {
      background: #fff;
    }
  }
}

.not-switch-button-root .label {
  color: rgb(110, 110, 110);
  border-right: none;
}
