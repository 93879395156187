@use 'client/src/styles/colors.module.scss';

$hoverRowColor: rgb(237, 238, 239);
$contextRowColor: #b7e4ff;

.grid-table-root {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  contain: style paint content layout;
  transform: translate(0, 0);
}

.grid-table-header {
  line-height: 32px;
  min-width: 0;
}

.grid-table-root.loading {
  .grid-table-virtual-backdrop,
  .grid-table-virtual-body,
  .grid-table-row {
    min-width: 100% !important;
  }
  :nth-child(2) {
    min-height: 20px;
  }
}

.grid-table-body {
  position: relative;
  overscroll-behavior-y: contain;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 0;
  contain: style paint content layout;
  transform: translate(0, 0);
}

.overflowable {
  min-height: 0;
  min-width: 0;
}

.grid-column-separator {
  height: 100%;
  width: 1px;
  top: 0;
  position: absolute;
  right: 4px;
  z-index: 49;
  user-select: none;

  cursor: col-resize;
  &:after {
    content: ' ';
    width: 1px;
    height: 60%;
    top: 20%;
    position: absolute;
    left: 3px;
    background: #505050;
    z-index: 9;
  }
}

.grid-table-root {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 0;
  height: 100%;
  text-align: center;
  position: relative;
  transform: 'translate(0, 0)';
}

.grid-table-cell {
  position: relative;
  line-height: 26px;
  border: 1px solid transparent;
  padding-left: 6px;
  padding-right: 6px;
  width: inherit;
  &,
  .header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
body:not(.client-portal):not(.dark-grid-table) .grid-table-cell.selected {
  border: 1px solid colors.$primaryBlue;
}
.grid-table-header {
  font-weight: 600;
  font-size: 12px;
}

body:not(.dark-grid-table):not(.client-portal) {
  .grid-table-root {
    border: 1px solid #bdc3c7;
  }
  .drag-clone .grid-table-cell,
  .drag-clone .grid-table-cell,
  .grid-table-header .grid-table-cell {
    border-style: solid;
    border-color: #bdc3c7;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    border-width: 0;
  }
  .grid-column-separator::after {
    background-color: rgba(0, 0, 0, 0.14);
    font-weight: 600;
    font-size: 12px;
  }
  .drag-clone .grid-table-cell .grid-column-separator::after {
    display: none;
  }
  .grid-table-row {
    border-color: #d9dcde;
    background-color: white;
    color: #000;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid rgb(217, 220, 222);
    &:nth-child(even) {
      background-color: #fcfdfe;
    }
    &.hovered {
      background-color: $hoverRowColor;
    }
    &.contexted {
      background-color: $contextRowColor;
    }
  }
  .grid-table-header.grid-table-row {
    background-color: rgb(245, 247, 247);
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
    font-size: 12px;
    border-bottom: 1px solid #bdc3c7;
  }
}
.grid-table-row {
  display: flex;
  contain: style paint content layout;
}

.grid-table-context-menu {
  z-index: 9999;
  border: 1px solid #bdc3c7;
  overflow-y: auto;
  user-select: none;
  position: fixed;
  width: 200px;
  margin-bottom: 4px;
  margin-top: 4px;
  cursor: default;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 2px;
  padding: 8px 0px;
  > * {
    padding: 8px 16px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    &:hover {
      cursor: pointer;
      background-color: rgb(237, 238, 239);
    }
  }
  .is-disabled {
    pointer-events: none;
    color: grey;
  }
}
// ._gotc,
.grid-table-root .sort-asc:after,
.grid-table-root .sort-desc:after {
  position: absolute;
  content: ' ';
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
}
.grid-table-root .sort-asc:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #666;
}
.grid-table-root .sort-desc:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #666;
}

.grid-table-column-filter {
  visibility: hidden;
  cursor: pointer;
  color: #424242;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  max-height: 80vw;
  overflow-y: auto;
}
.grid-table-set-column-filter {
  max-height: 400px;
  overflow-y: auto;

  .ui.input {
    height: 28px;
    margin-bottom: 10px;

    input {
      border: 1px solid #3d3b3b;
    }
  }
}
.grid-table-column-filter.active {
  visibility: visible;
}

.grid-table-cell:hover .grid-table-column-filter {
  visibility: visible;
}

.grid-table-root .grid-table-header .grid-table-cell.has-sort-applied {
  position: relative;
  padding-right: 21px !important;
  width: inherit;
  box-sizing: border-box;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.grid-table-cell:hover.has-filter .header {
  padding-left: 18px;
}
.grid-table-cell.has-filter-applied {
  position: relative;
  &:before {
    content: '\e914';
    font-family: agGridBalham;
    font-size: 16px;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    height: 16px;
    line-height: normal;
    speak: none;
    text-size-adjust: 100%;
    text-transform: none;
    user-select: none;
    white-space: nowrap;
    width: 16px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: 5px;
    top: 0;
  }
}

.grid-table-column-filter-dialog {
  min-height: 50px;
  background-color: white;
  border-color: #aaa;
  color: black;
  padding: 8px;
  * {
    white-space: nowrap;
  }
}
.grid-table-set-filter {
  * {
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
    text-align: left;
  }
  label {
    &,
    input {
      cursor: pointer;
    }
    display: grid;
    grid-template-columns: auto 1fr;
    span {
      padding-left: 8px;
    }
  }
}

.grid-table-filter-actions {
  display: flex;
  justify-content: space-between;
}

.grid-table-column-filter-conditions-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
}

.grid-table-column-filter-conditions-or {
  position: relative;
  width: 24px;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  .or {
    color: grey;
    transform: rotate(270deg);
  }
  .line {
    border-right: 1px solid grey;
    margin: 8px 10px;
  }
}

.grid-table-date-column-filter {
  .rdt {
    display: inline-block;
    padding: 2px;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 206);
    vertical-align: baseline;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 2px;
    &,
    input {
      color: black;
    }
  }
}

.grid-table-virtual-backdrop {
  width: 100%;
  min-height: 1px;
}

.grid-table-virtual-body {
  position: relative;
  .grid-table-rows {
    .grid-table-row {
      left: 0;
      right: 0;
      .grid-table-cell {
        left: 0;
        top: 0;
      }
    }
  }
}

.grid-table-virtual-measure {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
}

.grid-table-drag-clone {
  text-align: center;
  transform: scale(1/0.8);
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #aaa;
  padding: 4px 8px;
}

.grid-table-status-box {
  position: absolute;
  top: 50%;
  left: 50%;
  > div {
    margin-top: 22px;
    transform: translate(-50%, -50%);
  }
}

.grid-table-root .grid-table-header .grid-table-cell {
  height: 28px;
}

.grid-table-body {
  white-space: nowrap;
}
.grid-table-mid-viewport,
.grid-table-left-viewport,
.grid-table-right-viewport {
  flex-grow: 0;
  flex-shrink: 0;
  overscroll-behavior-x: contain;
  overflow-x: auto;
  overflow-y: visible;
  display: inline-block;
  ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
}

.grid-table-left-viewport {
  border-right: 1px solid rgb(194, 195, 194);
}
.grid-table-right-viewport {
  border-left: 1px solid rgb(194, 195, 194);
  display: flex;
  align-items: right;
}

.grid-table-bottom {
  .grid-table-mid-viewport,
  .grid-table-left-viewport,
  .grid-table-right-viewport {
    min-height: 1px;
    &::-webkit-scrollbar {
      display: initial;
    }
    -ms-overflow-style: initial; /* IE and Edge */
    scrollbar-width: initial; /* Firefox */
    overflow-y: visible;
  }
}

.grid-table-cell-wrapper {
  position: absolute;
  transform: translateX(var(--cellLeft));
  width: var(--cellWidth);
  height: var(--cellHeight);
}

.grid-table-row-wrapper {
  position: absolute;
  transform: translateY(var(--rowTop));
  height: var(--rowHeight);
  width: var(--rowWidth);
}

.grid-table-top
  .grid-table-virtual-body:not(:last-of-type)
  .grid-table-cell-wrapper:after {
  position: absolute;
  right: 0;
  top: 20%;
  bottom: 20%;
  width: 1px;
  content: ' ';
  background: rgba(0, 0, 0, 0.14);
}
