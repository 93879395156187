.accountsTabItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  border-bottom: 2px solid transparent; // this offsets the bottom border when it's active or hovered

  p,
  svg {
    letter-spacing: var(--g1-typography-body2-letter-spacing);
    color: var(--g1-color-galaxy-black-100);
  }

  &.active {
    border-bottom-color: var(--g1-color-galaxy-black-100);
  }
}

.accountsNavItem {
  display: flex;
  gap: var(--g1-spacing-xs);
  cursor: pointer;
  align-items: center;
}

.dropdownMenu {
  :global(.MuiPopover-paper) {
    min-width: 350px;
  }

  .accountsMenuItem {
    padding: 12px var(--g1-spacing-s);
    border-bottom: 1px solid var(--g1-color-galaxy-black-600);

    color: var(--g1-color-galaxy-black-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .accountsMenuItem:last-child {
    border-bottom: none;
  }
}
