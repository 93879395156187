.draggable {
  width: inherit;
  height: inherit;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  &.dragging {
    opacity: 0.5;
  }
}

.drag-clone-outer-wrapper {
  left: 0;
  top: 0;
  z-index: 10000;
  opacity: 1;
  position: absolute;
  display: none;
  transition: top 0.3s;
  .drag-clone {
    transform: translateX(-50%);
  }
  &.visible {
    display: grid;
  }
}

.drag-indicator {
  left: 0;
  top: 0;
  box-shadow: 0px 0px 10px rgb(0, 174, 255);
  background-color: rgb(0, 174, 255);
  width: 2px;
  position: absolute;
}
