.resizable-grid.default {
  .grid-item {
    padding: 8px;
    .ag-root,
    .title,
    button {
      cursor: default;
    }
  }
  display: flex;
}

.resizable-grid.default {
  flex-direction: column;
  .use-seperator {
    height: 21px;
    position: absolute;
  }
  .use-seperator {
    cursor: ew-resize;
  }
}

.resizable-grid.horizontal.default {
  flex-direction: row;
}

.resizable-grid.horizontal.default {
  position: relative;
  .use-seperator {
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
  }
}

.resizable-grid {
  position: relative;
}
