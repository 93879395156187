.virtualized-select-root {
  color: #f5f5f5;
}

.virtualized-select-placeholder {
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  width: 100%;
}

.virtualized-select-text {
  color: orange;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 0%;
  width: 100%;
  padding: 2px 8px;
  overflow: hidden;
  height: inherit;
}

.virtualized-select-input input {
  box-sizing: content-box;
  width: 100%;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
}

.virtualized-select-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(45, 52, 54);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 38px;
  position: relative;
  box-sizing: border-box;
  border-color: rgb(66, 66, 66);
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  transition: all 100ms ease 0s;
  outline: 0px !important;
}

.virtualized-select-container {
  position: relative;
}
.virtualized-select-indicator-container {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;

  .indicator {
    color: rgb(204, 204, 204);
    display: flex;
    box-sizing: border-box;
    padding: 8px;
    transition: color 150ms ease 0s;
  }

  svg {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
  }
}

.virtualized-select-indicator-separator {
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.virtualized-select-list {
  position: absolute;
  width: 100%;
  border-bottom: 5px solid #b73a3a;
  z-index: 1000;
}

.virtualized-select-input input::placeholder {
  color: transparent;
}
.virtualized-select-root.hasText {
  .virtualized-select-input input {
    color: inherit;
  }

  .virtualized-select-placeholder {
    display: none;
  }
}
