.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background-color: var(--g1-color-galaxy-black-800);

  & > :global(.MuiScopedCssBaseline-root) {
    height: 100%;

    // position relative and z-index creates new stacking context that prevents
    // ag-grid thead cells to overlap environment banner at the bottom
    position: relative;
    z-index: 0;
  }

  &:has(> .environmentBanner) {
    & > :global(.MuiScopedCssBaseline-root) {
      height: calc(100% - 40px);
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: var(--g1-color-galaxy-black-800);
  }

  :global(#margin-app) {
    overflow: auto;
  }
}
