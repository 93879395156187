.modalHeader {
  display: block;
  margin-top: var(--g1-spacing-s);
}

.modalContent {
  margin-left: var(--g1-spacing-m);
  margin-right: var(--g1-spacing-m);
  color: var(--g1-color-galaxy-black-400);
  text-align: center;
}

.modalFooter {
  display: grid;
  width: 100%;
  padding: 0 var(--g1-spacing-xl);
  gap: var(--g1-spacing-xs);
}
