@use 'client/src/styles/colors.module.scss';

.loader {
  padding-inline: var(--g1-spacing-s, 16px);
  padding-block-end: var(--g1-spacing-xs, 8px);
  isolation: isolate;

  &::after {
    content: '';
    position: fixed;
    inset: 0;
    background-color: colors.$background-primary;
    z-index: -1;
  }

  .appHeader {
    height: 40px;
    display: flex;
    align-items: center;
    gap: var(--g1-spacing-s, 16px);

    & > * {
      background-color: var(--g1-color-galaxy-black-600, colors.$darkGrey01);
    }

    .logo {
      width: 120px;
      height: 80%;
      margin-right: auto;
    }

    .navItem {
      width: 100px;
      height: 85%;
    }

    .avatar {
      height: 75%;
      aspect-ratio: 1;
      margin-left: auto;
    }

    .userMenu {
      height: 80%;
      width: 100px;
    }
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    .message {
      width: 25%;
      background-color: var(--g1-color-galaxy-black-600, colors.$darkGrey01);
    }
  }
}
