.mainNavMenu {
  position: relative;
  width: 100%;
  font-size: var(--g1-typography-subtitle2-font-size);
  color: var(--g1-color-galaxy-black-100);
  height: 80px;
  display: flex;
  background-color: var(--g1-color-galaxy-black-800);
  border-bottom: 1px solid var(--g1-color-galaxy-black-600);
  position: relative;
  align-items: center;

  .rightMenuContainer {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    align-items: center;
    flex-shrink: 0;

    .depositWithdrawBtn {
      margin-right: 24px;
      text-wrap: nowrap;
      flex-shrink: 0;
    }

    .user {
      border-left: 1px solid var(--g1-color-galaxy-black-600);
      padding-left: 24px;
      height: 48px;
      align-self: center;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .chevron {
        margin-right: 8px;
        display: inline-flex;
        cursor: pointer;
      }

      .firstName {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        overflow: hidden;
      }
    }
  }
}

.listPopper {
  position: absolute;
  top: calc(100%);
  background-color: var(--g1-color-galaxy-black-800);
  z-index: 1;
  border-radius: 4px;
  border: 1px solid var(--g1-color-galaxy-black-100);
  text-align: center;
  right: 1rem;
  li {
    list-style: none;
    padding: 8px 16px;

    &:hover {
      background-color: var(--g1-color-galaxy-black-600);
    }

    a {
      display: block;
      color: var(--g1-color-galaxy-black-100);
      width: 100%;
    }
  }
}
