.admin-panel-root {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 20px;
  padding-bottom: 20px;
  .searchWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
  }
  .admin-search-results {
    height: 75vh;
    padding-top: 25px;
  }
  .ui.secondary.pointing.menu .active.item {
    border-color: #499ae5;
  }
}
