$body-font: Lato, Helvetica, sans-serif;
$heading-font: Lato, Helvetica, sans-serif;
$icon-font: Icons;
$outline-icon-font: outline-icons;

$font-small: 0.75rem;
$font-sm: 0.875rem;
$font-medium: 1rem;
$font-large: 1.25rem;

/* font weights */
$token-font-weight-regular: 400;
$token-font-weight-semi-bold: 600;
$token-font-weight-bold: 700;
