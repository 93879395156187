.negative {
  color: red;
}

.settlements-root {
  height: 100%;
  .content-wrapper {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto auto 1fr;
  }
  .grids-container {
    min-height: 0;
    .settlements-grid {
      box-sizing: border-box;

      .ag-body-horizontal-scroll {
        display: none;
      }
    }
  }
  .filter-controls.actions {
    margin-bottom: 20px;
  }
}

.exposure-root {
  .ag-theme-balham {
    margin-bottom: 80px;
  }
}

._settlements,
._transfers {
  .virtualized-select-control,
  .virtualized-select-list {
    background: #fff;
    * {
      color: rgba(191, 191, 191, 0.87);
    }
    .justifier {
      &,
      * {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
  }
  .virtualized-select-indicator-container {
    .indicator:before {
      color: rgba(0, 0, 0, 0.87);
      content: '\f0d7';
      font-family: Dropdown;
      font-weight: 400;
      font-style: normal;
      webkit-tap-highlight-color: transparent;
      font-size: 0.85714286rem;
      text-align: center;
      cursor: pointer;
      position: absolute;
      width: auto;
      height: auto;
      line-height: 1.21428571em;
      top: 0.78571429em;
      right: 1em;
      z-index: 3;
      margin: -0.78571429em;
      padding: 0.91666667em;
      opacity: 0.8;
    }
    path {
      display: none;
    }
  }
  .virtualized-select-root.open {
    .virtualized-select-control,
    .virtualized-select-list {
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
      border-color: #96c8da;
    }
    .virtualized-select-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    }
    .virtualized-select-list {
      border-top: 1px solid #fafafa;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .active {
        background: rgba(0, 0, 0, 0.03);
        color: rgba(0, 0, 0, 0.95);
      }
      .focused {
        background: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.95);
      }
    }
  }
  .allocation-cell {
    height: 28px;
    width: 100%;
    .ui.input {
      width: calc(100% - 34px);
    }
  }

  .unallocatedfunds {
    margin-top: 12px;
    text-align: right;
    color: red;
  }
}

.modal {
  position: relative;
}

.saving-net-settle-text {
  position: absolute;
  top: calc(50% - 76px);
  text-align: center;
  color: whitesmoke;
  text-shadow: -2px 2px #000;
  font-size: 32px;
  left: 0;
  right: 0;
}

.net-settle-root {
  .inner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ui.small.button {
    margin-left: 24px;
    font-size: 24px;
  }
}

.allocation-cell .button {
  cursor: default;
}

.counterparty-detail-root {
  position: relative;
  .back {
    position: absolute;
    left: -40px;
    top: 19px;
    font-size: 24px;
    color: #499ae5;
    &:hover {
      color: hsl(209, 75%, 49%);
    }
    text-shadow: 1px 1px 3px #aaa;
  }
  .balance-tables {
    display: flex;
    flex-flow: row wrap;
    > div {
      display: inline-block;
      margin-bottom: 20px;
    }
    .spacer {
      flex: 1 0 10px;
      max-width: 36px;
    }
  }
}

._settlements,
._transfers {
  .outstanding-label {
    margin: auto auto;
  }
  .ui.form .field.disabled > label {
    opacity: 1;
  }
  .ui.form .disabled.field {
    opacity: 1;
  }
  .ui.form .field.disabled .currencies-selector {
    opacity: 0.45;
  }
  .virtualized-select-indicator-separator {
    display: none;
  }
  .justifier {
    display: flex;
    justify-content: space-between;
  }
  .value {
    margin-right: 16px;
  }
  .outstanding {
    &::after {
      content: 'Outstanding';
      margin-left: 5px;
    }
  }
  .option {
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    height: 38px;
    line-height: 38px;
  }
}

.unsettled-trades-h3 > div,
.settlements-table-header .title {
  margin-right: 32px;
  display: inline-block;
}

.settlements-table-header {
  clear: both;
}

.net-settle-renderer {
  text-align: center;
}

.confirm-net-settle-changes {
  .before {
    text-decoration: line-through;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    &:after {
      position: absolute;
      content: ' ';
      top: 10px;
      right: -7px;
      width: 0.4em;
      height: 0.4em;
      border-right: 2px solid red;
      border-top: 2px solid red;
      transform: rotate(45deg);
    }
  }
}

.cp-detail-grids-wrapper {
  margin-top: 16px;
}

#cp-grid .ag-overlay-wrapper {
  position: relative;
}

.balance-tables .ag-overlay-no-rows-center,
#cp-grid .ag-overlay-wrapper .ag-overlay-no-rows-center {
  position: absolute;
  bottom: 7px;
}
#NetBalanceTable .ag-overlay-wrapper .ag-overlay-no-rows-center {
  top: 40px;
}

.cp-detail-grids-wrapper .use-seperator {
  background: linear-gradient(0deg, #bdd4e7 0%, #8693ab 50%, #bdd4e7 100%);
  border-left: none;
  width: 2px;
  &:after {
    content: '';
  }
}

.settlements-root {
  .ag-row-focus .ag-cell {
    background-color: darken(#fff, 13) !important;
  }
  .ag-row-hover .ag-cell {
    background-color: darken(#fff, 8) !important;
  }
}

.settlement-cell-pt {
  font-style: italic;
}
.error-message-centered {
  text-align: center;
  font-size: 21px;
  color: red;
}

.yellow-status-dot,
.blue-status-dot {
  background: #499ae5;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
}
.yellow-status-dot {
  background: rgb(201, 195, 1);
}

.error-indicator {
  color: red;
}
