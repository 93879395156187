.button:global(.ui.animated.button) {
  width: 7ch;
  padding: 4px 0;
  font-size: 0.8em;

  white-space: nowrap;
  overflow: hidden;

  :global(.visible.content) {
    margin: auto;
  }
}
