.create-settlement-dialog {
  &:hover {
    background-color: #fafafa;
  }
  .detail {
    color: black;
  }
  .kind {
    color: #555;
  }
  .type {
    width: 140px;
  }
  .settlement-detail.error {
    background-color: #fff7f7;
    .allocated-to-trades,
    .allocated-to-transfers {
      color: red;
    }
  }
  .set-processing {
    position: absolute;
    right: 16px;
    top: 8px;
    vertical-align: top;
  }
  .ui.form .field.error input[type='text'] {
    background-color: white;
  }
}
.request-settlement-dialog,
.create-settlement-dialog {
  .settlement-header {
    display: flex;
    justify-content: space-between;
    padding-right: 38px;
    align-items: center;
  }
  .settlement-detail {
    position: relative;
    border-radius: 3px;
    margin-bottom: 8px;
    padding: 8px;
    .detail {
      cursor: pointer;
      div {
        display: inline-block;
      }
      > div {
        margin-right: 16px;
      }
      .qty,
      .currency {
        padding-right: 4px;
        font-weight: bold;
      }
    }
  }
  .settlement-tables {
    margin-top: 8px;
    margin-left: 16px;
  }

  .pane-wrapper {
    position: relative;
  }
}
.settlement-tables {
  position: relative;
}
.clear-allocations {
  position: absolute;
  right: 0;
  top: 10px;
  span {
    cursor: pointer;
    &:hover {
      color: black;
      text-decoration: underline;
    }
    &:first-of-type {
      padding-right: 16px;
    }
  }
}
