.container {
  padding-block: var(--g1-spacing-xs);
  padding-inline: var(--g1-space-l);

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--g1-spacing-xs);

    padding: var(--g1-spacing-xs);
    border: 1px solid var(--g1-color-yellow-300);
    border-radius: 8px;

    & > svg {
      color: var(--g1-color-yellow-300);
    }
  }
}
