.request-settlement-dialog {
  .settlement-detail {
    background-color: lighten(#121d27, 12) !important;
    .detail {
      color: whitesmoke;
    }
    &:hover {
      background-color: lighten(#121d27, 15) !important;
    }
  }
  .trades-table-wrapper {
    margin-top: 8px;
    margin-left: 12px;
  }
  .type {
    width: auto;
    margin-right: 8px;
  }
}
