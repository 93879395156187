.galaxy-tooltip-wrapper {
  display: none;
  z-index: 1001;
  position: absolute;
  pointer-events: none;
}
.galaxy-tooltip-wrapper.default {
  pointer-events: none;
}

.galaxy-tooltip-wrapper-2 {
  position: relative;
  width: 100%;
  height: 100%;
}
.galaxy-tooltip-wrapper-3 {
  position: absolute;
  width: auto;
  height: auto;
}

.galaxy-tooltip-wrapper-4 {
  position: absolute;
  pointer-events: all;
}

.galaxy-tooltip-wrapper {
  &.default {
    .galaxy-tooltip-wrapper-4 {
      background: #3d3b3b;
      color: white;
      border-radius: 2px;
      border: 1px solid black;
      padding: 4px 8px;
      white-space: nowrap;
    }
    .galaxy-tooltip-wrapper-4:before {
      content: ' ';
    }
  }
}

.galaxy-tooltip-wrapper.active {
  display: block;
}

.Tooltip-root,
.Tooltip-wrapper {
  display: inline-block;
}
