.personnelMenu {
  min-width: 320px;

  a,
  a:hover,
  a:active {
    flex: 1;
    color: var(--g1-color-galaxy-black-100);
    padding: var(--g1-spacing-xs) var(--g1-spacing-s);
  }

  li {
    padding: var(--g1-spacing-xs) var(--g1-spacing-s);
    height: 40px;

    &:hover {
      background-color: var(--g1-color-galaxy-black-600);
    }

    &:has(> a) {
      padding: 0;
    }
    &.entityItem {
      padding: 12px var(--g1-spacing-s);
    }
    &.entityTitle:global(.Mui-disabled) {
      opacity: 1;
    }
  }

  .entityItem {
    display: flex;
    justify-content: space-between;

    .entityNameContainer {
      display: flex;
      align-items: center;
      gap: var(--g1-spacing-xs);

      .entityName {
        max-width: 228px;
      }
    }
  }

  .signOutItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grayLine {
    border-top: 1px solid var(--g1-color-galaxy-black-500);
  }

  .textGalaxyBlack400 {
    color: var(--g1-color-galaxy-black-400);
  }

  .textGalaxyBlack100 {
    color: var(--g1-color-galaxy-black-100);
  }

  .textGalaxyTeal300 {
    color: var(--g1-color-teal-300);
  }

  .bgGalaxyBlack700 {
    background-color: var(--g1-color-galaxy-black-700);
  }
}

.textElipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap-mode: nowrap;
}

.tooltipText {
  text-align: center;
}

.user {
  --truncating-width: 132px;

  display: flex;
  align-items: center;
  gap: var(--g1-spacing-xs);

  .userNameContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: none;
    max-width: 132px;
  }

  .userName {
    max-width: var(--truncating-width);
  }

  .entityName {
    color: var(--g1-color-galaxy-black-400);
    max-width: var(--truncating-width);
  }
}
