@use 'client/src/styles/mixins/clientStyles.module.scss' as cs;
@use 'client/src/styles/colors.module.scss';
@use 'client/src/styles/typography.module.scss';
@use 'client/src/styles/mixins/galaxyScrollbar.module.scss' as scrollbar;

.root {
  background-color: colors.$background-primary;
  min-width: 970px;
  overflow-x: auto;
  color: colors.$textColor;

  @include cs.clientPortalStyles;

  //=======================================
  //GRID TABLE STYLES
  //=======================================
  &:global(:not(._agency)) :global .grid-table-root {
    background-color: colors.$background-secondary;
    border: none;
    border-radius: 5px;

    .grid-column-separator {
      height: 28px;
      &::after {
        background-color: transparent;
      }

      &:hover {
        background-color: rgb(61, 64, 67);
      }
    }

    .grid-table-body {
      font-size: 14px;
      font-family: typography.$body-font;

      &:not(.grid-table-top) {
        overflow-y: auto;

        @include scrollbar.galaxy-scrollbar;
      }

      &:not(.grid-table-bottom) {
        .grid-table-row > div {
          background-color: colors.$tertiaryBackground;
        }
        .grid-table-mid-viewport {
          width: calc(100% - 5px);
        }
      }
    }

    .grid-table-top {
      .grid-table-mid-viewport {
        width: calc(100% - 5px);

        .grid-table-rows > div > div {
          background-color: colors.$tertiaryBackground;

          .Tooltip-wrapper {
            color: white;
            margin-bottom: 5px;
          }

          .header {
            margin-right: 10px;
          }

          .sort-desc,
          .sort-asc {
            font-family: Icons;
            &:after {
              border: none;
              content: '\f106';
              top: 0;
              right: 12px;
            }
          }

          .sort-desc:after {
            content: '\f107';
          }
        }
      }
    }
  }
}

.root :global .grid-table-context-menu {
  background-color: colors.$tertiaryBackground;
  color: colors.$textColor;
  border-color: colors.$grey;
  padding: 0;

  div:hover {
    background-color: colors.$primaryBlue;
    border-radius: 3px;
  }
}

//=============================================
// MODAL
//=============================================
.root :global .ui.modal {
  border: 1px solid colors.$tertiaryBackground;
  background-color: colors.$background-secondary;
  .header,
  .content,
  .actions {
    background-color: colors.$background-secondary;
    color: colors.$textColor;
  }

  .header {
    padding-bottom: 5px;
    border-bottom: none;
  }

  .content {
    padding-top: 5px;
  }

  .actions {
    display: flex;
    justify-content: center;
  }

  .ui.pointing.secondary.menu {
    border-bottom: 1px solid colors.$grey;

    .item {
      display: block;
      color: colors.$grey;
      border: none;
    }

    .active.item {
      color: colors.$textColor;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: colors.$background-secondary;
    }
  }

  .ui.segment {
    background-color: colors.$background-secondary;
    border: none;
    padding: 0;

    .grid-table-root {
      div:first-child {
        padding: 0;
      }

      div:nth-child(2) {
        min-height: 40px !important;
      }

      .grid-table-cell {
        .header {
          background-color: colors.$tertiaryBackground;
        }
      }

      div:nth-child(3) {
        .grid-table-mid-viewport {
          overflow-y: hidden;
          overflow-x: scroll;
          @include scrollbar.galaxy-scrollbar;
        }
      }
    }
  }
}
