@use 'client/src/styles/colors.module.scss';
@use 'client/src/styles/mixins/galaxyScrollbar.module.scss' as scrollbar;

@mixin clientPortalStyles {
  :global {
    .galaxy-header-flex {
      .header-border {
        border-top: 2px solid colors.$primaryBlue;
      }
      .subheader {
        font-size: 16px;
        font-weight: 700;
        color: colors.$grey;
      }
    }
    .galaxy-header {
      h1 {
        font-size: 22px;
        margin-bottom: 0;
        border-bottom: 2px solid colors.$primaryBlue;
      }
      .header-border {
        display: none;
      }
      .subheader {
        font-size: 16px;
        font-weight: 700;
        color: colors.$grey;
        margin: 8px 0;
        padding-top: 3px;
      }
    }
    .Toastify {
      .Toastify__toast--info,
      .Toastify__toast--error,
      .Toastify__toast--warning,
      .Toastify__toast--success,
      .Toastify__toast--default {
        --toastify-text-color-light: #{colors.$textColor};
        border-radius: 5px;
        opacity: 0.9;
      }
      .Toastify__progress-bar--info,
      .Toastify__progress-bar--error,
      .Toastify__progress-bar--default {
        --toastify-color-progress-light: #{colors.$textColor};
      }

      .Toastify__close-button {
        margin-block: auto;
        margin-inline: 0.5em;
        font-family: outline-icons;
        opacity: 1;

        &:before {
          content: '\f057';
          color: colors.$textColor;
        }

        & > svg {
          display: none;
        }
      }

      .Toastify__toast--info,
      .Toastify__toast--success {
        --toastify-color-light: #{colors.$toastSuccess};
      }
      .Toastify__toast--warning {
        --toastify-color-light: #{colors.$toastWarning};
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
      }

      .Toastify__toast--error {
        --toastify-color-light: #{colors.$toastError};
      }
    }
    .tooltip-dialog {
      border-radius: 2px;
      border: none;
      height: auto !important;

      .ui.selection {
        max-height: 450px;
        background-color: colors.$tertiaryBackground;
        color: colors.$textColor;
        margin-left: 10px;
        min-width: 165px;
        padding: 0;

        i {
          font-family: Icons !important;
          &:before {
            content: '\f107' !important;
          }
        }

        .item {
          color: white;

          &:hover {
            color: colors.$lightGrey01;
          }
        }
      }

      .ui.selection.active {
        max-height: 250px;
        padding: 0;
        margin-bottom: 20px;
        border: none;

        &:hover {
          border-color: transparent;
        }

        .text {
          margin: 12px 0 0 15px;
          color: colors.$textColor !important;
        }

        .visible.menu.transition {
          border-color: transparent;
        }

        .menu {
          position: relative;
          max-height: 150px;
          background-color: colors.$background-secondary;
          margin-top: 15px;
          background-clip: border-box;

          @include scrollbar.galaxy-scrollbar;

          .text {
            margin-left: 0;
          }

          &:hover {
            border-color: transparent;
          }

          .item:first-child {
            margin-top: 2px;
          }
          .item {
            background-color: colors.$tertiaryBackground;
            border: none;
            border-radius: 2px;
            margin-bottom: 2px;

            &:hover {
              background-color: colors.$primaryBlue;
            }
          }
        }
      }
    }
    .tooltip-dialog {
      background-color: colors.$background-secondary;
      border-radius: 3px;

      hr {
        display: none;
      }

      h3 {
        color: colors.$grey;
        font-size: 14px;
      }

      //Date filter
      .grid-table-date-column-filter {
        i.icon {
          margin-left: 5px !important;
        }

        .rdt {
          background-color: colors.$tertiaryBackground;
          input {
            color: colors.$darkTextColor;
          }
        }

        .rdtPicker {
          background-color: colors.$background-secondary;
          color: colors.$textColor;
          border: none;
          border-radius: 3px;
          margin-top: 5px;
          padding: 0 4px;

          .rdtDays {
            tfoot {
              border: none;
              position: absolute;
              width: 100%;
              justify-content: center;
              background-color: colors.$tertiaryBackground;
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
              text-align: center;
              right: 0;
              padding-left: 90px;

              &:hover {
                background-color: colors.$primaryBlue;
              }
              tr td {
                &:hover {
                  background-color: colors.$primaryBlue;
                }
              }
            }
          }

          thead tr {
            .rdtNext,
            .rdtPrev {
              vertical-align: middle;
              border: none;
              padding-left: 7px;
              &:hover {
                background-color: transparent;
              }

              span {
                border: 1px solid colors.$grey;
                border-radius: 2px;
                width: 18px !important;
                padding-bottom: 3px;

                &:hover {
                  background-color: colors.$primaryBlue;
                }
              }
            }

            .rdtSwitch {
              border: none;
              border-radius: 3px;
              &:hover {
                background-color: colors.$primaryBlue;
              }
            }

            .dow {
              color: colors.$grey;
            }
          }

          .rdtMonths {
            border: none;
          }

          .rdtYear,
          .rdtMonth,
          .rdtDay {
            &:hover {
              border-radius: 3px;
              background-color: colors.$primaryBlue;
            }
          }

          td.rdtOld {
            color: colors.$grey;
          }

          td.rdtActive {
            background-color: colors.$primaryBlue;
            border-radius: 3px;
            &:before {
              display: none;
            }
          }

          //Time picker
          .rdtTime {
            .rdtSwitch {
              border: none;
              position: absolute;
              width: 100%;
              background-color: colors.$tertiaryBackground;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              padding-top: 4px;
              right: 0;

              &:hover {
                background-color: colors.$primaryBlue;
              }
            }

            .rdtCounters {
              max-height: 115px;
              padding-top: 15px;

              .rdtCounter {
                margin-top: 20px;

                .rdtBtn {
                  line-height: 18px;
                  color: transparent;
                  border: 1px solid colors.$grey;
                  border-radius: 2px;
                  width: 18px !important;
                  padding-bottom: 3px;
                  font-family: Icons;
                  margin: 5px 0 5px 10px;
                  height: 20px;

                  &:before {
                    content: '\f106';
                    color: colors.$textColor;
                    margin-left: 3.5px;
                  }

                  &:hover {
                    background-color: colors.$primaryBlue;
                  }
                }
              }
            }
          }
        }
      }

      //Toggle filter
      .grid-table-set-filter {
        color: colors.$textColor;
      }

      //Filters with input
      .ui.dropdown {
        color: white;
        margin-right: 10px;
        i {
          font-family: Icons !important;
          &:before {
            content: '\f107' !important;
          }
        }
      }

      .ui.dropdown.active {
        .menu {
          background-color: colors.$background-secondary;

          .item {
            color: colors.$textColor !important;
            background-color: colors.$tertiaryBackground;
            border-radius: 2px;
            margin-bottom: 2px;

            &:hover {
              background-color: colors.$primaryBlue;
            }
          }
        }
      }

      .ui.input {
        height: 28px;

        input {
          color: colors.$textColor;
          min-width: 150px;
          background-color: colors.$tertiaryBackground;
          margin-left: 4px;
        }
      }

      h3 {
        margin-bottom: 5px;
      }

      .grid-table-column-filter-conditions-wrapper {
        min-height: 100px;

        .ui.dropdown {
          margin-left: 5px;
        }

        div:nth-child(2) {
          div:not(.grid-table-number-range-filter) {
            .ui.input {
              display: block;
              margin: 5px 0;

              input {
                height: 28px;
              }
            }
          }

          div:first-of-type {
            .ui.dropdown {
              margin-top: 0;
            }
          }
        }

        .grid-table-column-filter-conditions-or {
          .line {
            height: 35px;
            border-color: colors.$grey;
            margin-bottom: 3px;
          }
        }

        .or {
          color: colors.$textColor;
        }

        .grid-table-number-range-filter {
          margin-bottom: 5px !important;
          .range-filter {
            .range-inputs {
              display: flex;

              .range-filter-separator {
                color: transparent;
                width: 15px;
                height: 20px;
                border-bottom: 1px solid colors.$grey;
                margin-left: 5px;
                margin-right: 2px;
              }
            }
          }
        }
      }

      .single-filter-condition {
        .grid-table-number-range-filter {
          margin-bottom: 5px !important;
          .range-filter {
            display: flex;
            .range-inputs {
              display: flex;
              .range-filter-separator {
                color: transparent;
                width: 15px;
                height: 15px;
                border-bottom: 1px solid colors.$grey;
                margin-left: 5px;
                margin-right: 2px;
              }
            }
          }
        }
      }

      .no-conditions {
        color: colors.$textColor;
        margin-left: 20px;
      }

      .grid-table-filter-actions {
        margin-top: 10px;
        .ui.button {
          border-radius: 2px;
          padding: 6px 15px;
          font-size: 14px;
        }

        .ui.negative.button {
          background-color: colors.$redButton !important;
        }

        .ui.primary.button {
          background-color: colors.$primaryBlue !important;
        }

        .ui.positive.button {
          background-color: colors.$darkGreen !important;
        }
      }
    }
    .ui.toggle.checkbox {
      position: relative;
      text-align: left;

      label {
        font-size: 14px;
        color: colors.$textColor !important;

        &::before {
          background-color: colors.$background-secondary !important;
          border: 1px solid colors.$toggleBorder;
        }
      }
    }

    .ui.checked.toggle.checkbox {
      label {
        color: colors.$textColor !important;
        &::before {
          background-color: colors.$green;
        }
      }
    }
    .ui.secondary.pointing.menu {
      margin: 0;
      .item {
        box-sizing: border-box;
        color: colors.$lightGrey02;

        &:hover {
          color: colors.$lightGrey01;
        }

        &.active {
          color: colors.$white;
          position: relative;
          border-bottom: 3px solid currentColor;
        }
      }
    }
  }
}
