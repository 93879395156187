.transfers-root .filter-controls .filter-controls {
  margin-bottom: 10px;
  display: flex;
  &,
  & > * {
    height: 24px;
    vertical-align: baseline;
  }
}

.filter-controls .DateRangePickerInput {
  border: none;
}
.filter-controls .DateInput_input {
  font-size: 14px;
  height: 24px;
  line-height: 12px;
  padding-right: 0px;
  padding-left: 0px;
  &.DateInput_input__focused {
    border-color: #499ae5;
  }
}

.filter-controls .DateInput {
  width: 73px;
  margin-right: 8px;
  margin-left: 8px;
  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }
}

.filter-controls .CalendarDay__selected,
.filter-controls .CalendarDay__selected:active {
  background: #499ae5;
  border: 1px double #499ae5;
  color: #fff;
}

.filter-controls .CalendarDay__selected_span {
  color: rgb(255, 255, 255);
  background: lighten(#499ae5, 10);
  border-width: 1px;
  border-style: double;
  border-color: #499ae5;
  border-image: initial;
}

.filter-controls .CalendarDay__selected_span:hover,
.filter-controls .CalendarDay__selected:hover {
  background: darken(#499ae5, 10);
  border-color: #499ae5;
}

.filter-controls .DateRangePicker_picker {
  top: 40px !important;
}
.filter-controls .DateInput_fang {
  top: 30px !important;
}
