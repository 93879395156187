@use 'client/src/styles/colors.module.scss';

.modal {
  top: 200px;
  padding: 0;
  border-radius: 5px;
  background: colors.$tertiaryBackground;
  box-shadow: 0px 2px 5px 0px colors.$darkGrey01;
  overflow: hidden;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  background: colors.$red02;
  color: colors.$textColor;
}

.content {
  padding-block: 2em 3em;
  padding-inline: 4em;
  border: 1px solid colors.$darkGrey02;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  > h1 {
    margin-bottom: 1em;
    text-align: center;
    color: colors.$textColor;
  }

  :global {
    .ui.buttons .or::before {
      border: 1px solid colors.$lightGrey03;
      background-color: colors.$tertiaryBackground;
      color: colors.$textColor;
      line-height: 1.5;
    }
  }
}
