.envBanner {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;

  &:not(.uat) {
    background-color: var(--g1-color-hardy-orange-400);
  }

  &.uat {
    background-color: var(--g1-color-bright-yellow-400);
  }

  .text {
    opacity: 0.5;
    font-family: var(--g1-typography-body2-font-family);
    font-size: var(--g1-typography-body2-font-size);
    font-weight: var(--g1-typography-body2-font-weight);
    line-height: var(--g1-typography-body2-line-heigh);
    letter-spacing: var(--g1-typography-body2-letter-spacing);

    fill: var(--g1-color-galaxy-black-100);
  }
}
