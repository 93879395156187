.filter-controls {
  width: 250px;
  white-space: nowrap;
  .ui.dropdown {
    margin-right: 0.25rem;
  }
  .ui.dropdown > .dropdown.icon {
    margin-left: 0.25rem;
  }
}
.live-risk-root .filter-controls .filter-controls {
  display: flex;
}

.live-risk-toggle-columns {
  i {
    font-size: 18px;
  }
  width: 40px;
  color: #dcddde;
  cursor: pointer;
  &:hover,
  &.visible {
    color: #2185d0;
  }
  &.visible:hover {
    color: #1869a7;
  }
}
.live-risk-show-notional {
  display: flex;
  .ui.toggle.checkbox {
    margin-right: 12px;
  }
  .input input {
    width: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ui.label {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.filter-control .left {
  float: left;
}

.toggler-root {
  position: relative;
  height: 100%;
  width: 15px;
  color: rgba(130, 130, 130, 0.5);
  i {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    vertical-align: middle;
  }
  cursor: pointer;
  &:hover {
    color: rgb(80, 80, 80);
    .inner,
    .inner:after {
      background-color: rgba(189, 195, 199, 0.5);
    }
  }
  .inner {
    position: relative;
    height: 100%;
    margin-left: 3px;
    &,
    &:after {
      background-color: hsla(0, 0%, 87%, 0.5);
    }
    &:after {
      content: ' ';
      height: 100%;
      width: 1px;
      position: absolute;
      right: -2px;
      top: 0;
    }
  }
}
.live-risk-root {
  .table-wrapper {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
  }
  width: 100%;
  min-height: 0;
  padding-bottom: 15px;
}
.position-report-root {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  &,
  .table-root-wrapper,
  .table-wrapper {
    position: relative;
    height: 100%;
    min-height: 0;
  }
}

._otc .table-root-wrapper {
  position: relative;
}

.live-risk-toggle-columns-dialog-wrapper {
  z-index: 4;
  pointer-events: all;
  user-select: none;
}
.live-risk-toggle-columns-dialog {
  background-color: #fafafa;
  padding: 8px 16px;
  width: 800px;
  border: 1px solid #bdc3c7;
  white-space: nowrap;
  .header {
    margin-bottom: 8px;
    font-weight: bold;
  }
  button.ui.button {
    margin-top: 10px;
  }
}

.live-risk-toggle-columns-sections {
  display: flex;
  justify-content: baseline;
  justify-items: stretch;
  flex-wrap: wrap;
}
.live-risk-toggle-column-section {
  flex: 1 1 0;
  margin-bottom: 10px;
  padding-right: 8px;
  h3 {
    display: inline-block;
    margin: 0;
    margin-left: 8px;
    margin-bottom: 8px;
    text-transform: capitalize;
  }
}

.live-risk-toggle-column-option {
  &,
  * {
    cursor: pointer;
    user-select: none;
  }
  label {
    > * {
      vertical-align: baseline;
      line-height: 12px;
      display: inline-block;
    }
    input {
      margin-right: 4px;
    }
  }
  &.hidbn {
    color: #bdc3c7;
    * {
      text-decoration: line-through;
    }
  }
}

.live-risk-toggle-columns-tooltip {
  user-select: none;
}

.live-risk-hidden-exchanges-col,
.live-risk-hidden-exchanges-col-header {
  background-color: rgba(100, 100, 100, 0.5) !important;
  color: white;
}

.flex {
  position: relative;
  display: flex;
  flex-direction: row;
}

.live-risk-balance-toggles-dialog {
  min-width: 250px;
  padding: 12px;
  background: white;
  border: 1px solid grey;
  > div:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 12px;
  }
  .ui.toggle.checkbox {
    margin-bottom: 8px;
  }
}

.live-risk-trade-report-dialog {
  .scrolling.content {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
  }
}
