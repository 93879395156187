$ag-icons-path: '~ag-grid-community/src/styles/ag-theme-balham/icons/';
@use '~ag-grid-community/styles' as ag;
@use '../../styles/typography.module.scss';
@use '../../styles/colors.module.scss';

$active: #00b0ff;
$alt-background: #2a3138;
$background-color: #121d27;
$border-color: #424242;
$chip-background-color: darken($alt-background, 5);
$chrome-background: darken(#121d27, 2);
$header-background-color: $chrome-background;
$header-cell-hover-background-color: darken($chrome-background, 1);
$font-family: typography.$body-font;
$font-size: 12px;
$foreground-color: #fff;
$disabled-foreground-color: rgba(
  $foreground-color,
  0.38
); // foreground-disabled
$foreground-color: rgba($foreground-color, 1); // foreground
$secondary-foreground-color: rgba($foreground-color, 1);
$hover-color: lighten($alt-background, 10);
$input-disabled-background-color: rgba(48, 46, 46, 0.3);
$odd-row-background-color: darken($alt-background, 2);
$range-selection-background-color: transparentize($active, 0.8);
$range-selection-highlight-color: $active;
$row-border-color: #5c5c5c;
$header-foreground-color: $secondary-foreground-color;
$tooltip-background-color: $header-background-color;
$value-change-delta-down-color: #e53935;
$value-change-delta-up-color: #43a047;
$value-change-value-highlight-background-color: transparentize(#16a085, 0.5);

@include ag.grid-styles(
  (
    theme: balham-dark,
    balham-active-color: $active,
    checkbox-background-color: colors.$white,
    checkbox-checked-color: colors.$darkGrey02,
    background-color: $background-color,
    border-color: $border-color,
    chip-background-color: $chip-background-color,
    disabled-foreground-color: $disabled-foreground-color,
    font-family: $font-family,
    font-size: $font-size,
    foreground-color: $foreground-color,
    header-background-color: $header-background-color,
    header-cell-hover-background-color: $header-cell-hover-background-color,
    header-foreground-color: $header-foreground-color,
    input-disabled-background-color: $input-disabled-background-color,
    odd-row-background-color: $odd-row-background-color,
    range-selection-background-color: $range-selection-background-color,
    range-selection-highlight-color: $range-selection-highlight-color,
    row-border-color: $row-border-color,
    row-hover-color: $hover-color,
    secondary-foreground-color: $secondary-foreground-color,
    tooltip-background-color: $tooltip-background-color,
    value-change-delta-down-color: $value-change-delta-down-color,
    value-change-delta-up-color: $value-change-delta-up-color,
    value-change-value-highlight-background-color:
      $value-change-value-highlight-background-color,
  )
);

.ag-theme-balham .ag-filter-apply-panel-button {
  padding: 0.25em 0.5em;
}

.client-portal .ag-theme-balham,
.ag-theme-balham-dark {
  .ag-ltr .ag-row-level-0 .ag-row-group-leaf-indent {
    margin-left: 0;
  }
}

.client-portal.client-portal.client-portal {
  .ag-floating-bottom-container,
  .ag-floating-top-viewport,
  .ag-floating-bottom-viewport,
  /* .ag-pinned-left-floating-bottom, */
  .ag-pinned-right-floating-bottom {
    background: lighten($alt-background, 2);
    color: white;
    font-weight: 500;
  }
  .ag-overlay-loading-wrapper {
    background-color: rgba(48, 46, 46, 0.3);
  }
}
